import React from 'react';
import Slider from 'react-slick';

import '../Carousel/styles.css';
import settings from './settings';
import { Slide1, Slide2, Slide3, Laptop, X96, Mobile } from './images';
import Container from '@components/Container';

export default () => {
  return (
    <section className={'presentation-container'}>
      <Container className="">
        <div className={'organizeContainer'}>
          <p className={'organizeHeader'}>
            Организуйте свое музыкальное пространство ​
          </p>
          <p className={'organizeHeaderP'}>
            Наш он-лайн сервис позволяет настроить музыкальный эфир вашего
            бизнеса под любые задачи.
          </p>
        </div>
        <Laptop>
          <Slider {...settings}>
            <div>
              <Slide1 />
            </div>
            <div>
              <Slide2 />
            </div>
            <div>
              <Slide3 />
            </div>
          </Slider>
        </Laptop>
        <div className={'deviceContainer'}>
          <p className={'organizeBody'}>
            Вы сможете поменять настроение в вашем заведении из любой точки
            мира!
            <br />
            <br />
            Вам не обязательно думать, как у вас будет играть музыка, мы об этом
            позаботились - плеер IMSTREAM решит все задачи.
          </p>
          <X96
            className={'x96'}
            imgStyle={{ objectPosition: 'bottom center', objectFit: 'contain' }}
          />
          <Mobile
            className={'mobile'}
            imgStyle={{
              objectFit: 'contain',
              marginLeft: '-10%',
              objectPosition: 'bottom center',
            }}
          />
        </div>
      </Container>
    </section>
  );
};
