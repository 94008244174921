import React from 'react';

import Intro from '@sections/Service/Intro';
import CTA from '@components/CallToAction';
import Layout from '@layouts/business';
import SEO from '@components/seo';
import { Presentation } from '@sections/Presentation';
import { SolutionPart, ServicePart } from '@sections/Service/List';

const Examples = () => (
  <Layout>
    <SEO title="Услуги и решения | IMstream" />
    <Presentation />
    <Intro />
    <SolutionPart />
    <ServicePart />
    <CTA />
  </Layout>
);

export default Examples;
