import React from 'react';
import Card from '../Card';

import styles from './styles.module.css';

export default ({ title, cards }) => (
  <div className={styles.container}>
    <h3 className={styles.title}>{title}</h3>
    <div className={styles.cardList}>
      {cards.map((data, index) => (
        <Card key={index} {...data} />
      ))}
    </div>
  </div>
);
