import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = ({ className = '' }) => (
  <StaticQuery
    query={graphql`
      query {
        abc: file(relativePath: { eq: "presentation/screenshot_1.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Img className={className} fluid={data.abc.childImageSharp.fluid} />
      );
    }}
  />
);
export default Image;
