import React from 'react';
import Container from '@components/Container';
import Row from './Row';

import data from './data.js';
import styles from './styles.module.css';

export const List = () => (
  <section className={styles.container}>
    <Container>
      <div className={styles.wrapper}>
        {data.items.map((item, index) => (
          <Row key={index} {...item} />
        ))}
      </div>
    </Container>
  </section>
);

export const ServicePart = () => (
  <section className={styles.container}>
    <Container>
      <div className={styles.wrapper}>
        <Row {...data.items[2]} />
      </div>
    </Container>
  </section>
);

export const SolutionPart = () => (
  <section className={styles.container}>
    <Container>
      <div className={styles.wrapper}>
        <Row {...data.items[1]} />
      </div>
    </Container>
  </section>
);
