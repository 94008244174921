import { Web1, Download1, Advertising2, Info1, Court2, Speech2 } from '@icons';

export default {
  title: 'Наши услуги',
  items: [
    {
      title: 'Музыкальный плеер',
      cards: [
        {
          Icon: Web1,
          title: 'Удобно',
          text:
            'Лёгкий и удобный сервис для управления музыкальным эфиром вашего бизнеса.',
        },
        {
          Icon: Download1,
          title: ' Свободное скачивание',
          text: 'Музыку можно слушать онлайн или скачать на своё устройство.',
        },
        {
          subtitle: '23',
          title: 'Жанра в каталоге',
          text:
            'В сервисе уже собраны десятки плейлистов по жанрам, стилям, тематическим праздникам и типам заведений.',
        },
      ],
    },
    {
      title: 'Индивидуальное музыкальное оформление',
      cards: [
        {
          subtitle: '47387',
          title: 'Треков в каталоге',
          text:
            'В нашей медиатеке огромный выбор музыкальных композиций различных жанров и направлений.',
        },
        {
          Icon: Advertising2,
          title: 'Собственные аудиоролики',
          text:
            'Вы можете добавить в эфир собственные рекламные ролики и указать частоту их проигрывания.',
        },
        {
          subtitle: '5',
          title: 'Музыкальных редакторов',
          text:
            'Профессиональные эксперты помогут создать правильный музыкальный фон вашего заведения.',
        },
      ],
    },
    {
      title: 'Юридическое сопровождение и консалтинг',
      cards: [
        {
          Icon: Info1,
          title: 'Правовая консультация',
          text:
            'Юридическое сопровождение и поддержка клиентов в сфере авторских и смежных прав.',
        },
        {
          Icon: Court2,
          title: 'Полностью лицензионно',
          text:
            'Вся музыка, размещённая в нашем сервисе, имеет разрешение правообладателей на воспроизведение и публичное исполнение.',
        },
        {
          Icon: Speech2,
          title: 'Правовая защищенность',
          text:
            'Участие в переговорах с организациями по коллективному управлению правами (РАО, ВОИС), защита интересов в судах и административных органах.',
        },
      ],
    },
  ],
};
