import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = ({ className = '', children }) => (
  <StaticQuery
    query={graphql`
      query {
        abc: file(relativePath: { eq: "presentation/laptop.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '16.2% 70% 14.9%',
            gridAutoRows: '8fr 88fr',
          }}
        >
          <Img
            className={className}
            fluid={data.abc.childImageSharp.fluid}
            style={{ gridArea: '1 / 1 / 3 / 4', height: '90%' }}
            imgStyle={{ objectFit: 'fill' }}
          />
          <div style={{ gridArea: '2 / 2 / 3 / 3' }}>{children}</div>
        </div>
      );
    }}
  />
);
export default Image;
