import React from 'react';
import styles from './styles.module.css';
import MediaQuery from 'react-responsive';

const Subtitle = ({ children }) => (
  <h4 className={styles.subtitle}>{children}</h4>
);

export default ({ title, text, subtitle, Icon }) => {
  const Header = () =>
    Icon ? <Icon className={styles.icon} /> : <Subtitle>{subtitle}</Subtitle>;

  return (
    <div className={styles.card}>
      <MediaQuery minWidth={768}>
        <Header />
        <span className={styles.title}>{title}</span>
      </MediaQuery>
      <p>{text}</p>
    </div>
  );
};
